import $ from "jquery";

$(function () {
  $(".slideshow").each(function () {
    const images = $(this).find(".slide").get() as HTMLDivElement[];
    const numImages = images.length;
    let currentIndex = 0;

    const stackImage = function (image: HTMLElement, index: number) {
      $(image).removeClass("fade-in");
      return $(image).css({
        zIndex: index
      });
    };

    const stackImages = function () {
      let i: number, j, len;
      for (i = j = 0, len = images.length; j < len; i = ++j) {
        const image = images[i];
        stackImage(image, (i - currentIndex + (numImages - 1)) % numImages);
      }
    };

    const showNextImage = function () {
      currentIndex = (currentIndex + 1) % numImages;
      stackImages();
      $(images[currentIndex]).addClass("fade-in");
    };
    stackImages();
    setInterval(showNextImage, 7000);
  });
});
